<template>
  <div class="box__body">
    <h3 data-test-id="contract-item-title">{{ title }}</h3>
    <div class="contracts">
      <div v-for="contract in contracts" :key="contract.id" class="contracts__item">
        <div class="flex-grow-1 d-lg-flex">
          <div data-test-id="contract-item-name" class="contracts__item__name">
            {{ contract.fileName }}
          </div>
          <div data-test-id="contract-item-date" class="contracts__item__date">
            {{ $t('submitDate', { submitDate: formatDate(contract.uploadedAt) }) }}
          </div>
        </div>
        <a
          data-test-id="contract-item-download"
          class="btn btn-download flex-shrink-0"
          :href="downloadUrl(contract)"
          v-tooltip="$t('download')"
          target="_blank"
        />
      </div>
    </div>
    <div>
      <a
        data-test-id="contract-item-create"
        class="btn btn-outline-gray btn-sm"
        :href="createLink"
        target="_blank"
      >
        {{ createLabel }}
      </a>
    </div>
  </div>
</template>

<script>
import env from '@/_helpers/env'
import { Dates } from '@/_helpers'

export default {
  props: {
    title: String,
    createLabel: String,
    createLink: String,
    contracts: Array
  },
  methods: {
    downloadUrl(contract) {
      return env.VUE_APP_ROOT_API + contract.downloadUrl
    },
    formatDate: Dates.formatDate
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';

.contracts {
  margin: 16px 0;

  & * {
    align-items: center;
  }

  &__item {
    border: 1px solid gray('gray-150');
    border-top-width: 0;
    padding: 10px 16px;
    display: flex;

    &:first-child {
      border-top-width: 1px;
    }

    &:hover {
      background-color: gray('gray-100');
    }

    &__name {
      flex-grow: 1;
      font-size: 17px;
      font-weight: 500;
    }

    &__date {
      flex-shrink: 0;
      margin-right: 20px
    }
  }
}
</style>
