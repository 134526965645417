<template>
  <loading-wrapper :is-loading="projectDetailsLoading">
    <div class="page">
      <project-details-header data-test-id="header" :project="project" />
      <tabs data-test-id="tabs" :active-tab="currentTab">
        <upload-documents
          slot="rightContent"
          data-test-id="upload-documents"
          :project-id="projectId"
          :project-status="project.status"
          :accreditation-active="accreditationActive"
          :missing-documents="project.missingDocuments"
        />
        <tab name="steps" data-test-id="steps" :title="$t('steps')">
          <project-steps-tab
            data-test-id="steps-tab"
            :project="project"
          />
        </tab>
        <tab name="contracts" data-test-id="contracts" :title="$t('contracts')">
          <contracts-tab
            data-test-id="contracts-tab"
            :prefilled-documents="project.prefilledDocuments"
            :submitted-documents="project.submittedDocuments"
            :project-id="projectId"
            :project-type="project.type"
            :buyers="project.buyers"
          />
        </tab>
        <tab name="documents" data-test-id="documents" :title="$t('documents')" :badge="documentsCount">
          <documents-tab
            :submittedDocuments="project.submittedDocuments"
            :gcrDocuments="project.gcrDocuments"
          />
        </tab>
      </tabs>
    </div>
  </loading-wrapper>
</template>

<script>
import Tabs from '@/components/molecules/Tabs'
import Tab from '@/components/atoms/Tab'
import DocumentsTab from '@/components/project/DocumentsTab'
import UploadDocuments from '@/components/project/UploadDocuments'
import LoadingWrapper from '@/components/organism/LoadingWrapper'
import ProjectStepsTab from '@/components/project/ProjectStepsTab'
import ContractsTab from '@/components/project/ContractsTab'
import ProjectActions from '@/components/project/ProjectActions'
import { Consts } from '@/_helpers'
import { mapGetters, mapState } from 'vuex'
import getProjectDetails from '@/graphql/accreditations/projects/getProjectDetails.graphql'
import ProjectDetailsHeader from '@/components/project/ProjectDetailsHeader'

require('../../utilities/page')

export default {
  components: {
    ProjectDetailsHeader,
    ContractsTab,
    ProjectStepsTab,
    DocumentsTab,
    Tabs,
    Tab,
    UploadDocuments,
    LoadingWrapper,
    ProjectActions
  },
  props: {
    projectId: String,
    currentTab: {
      type: String,
      default: () => 'steps'
    }
  },
  data() {
    return {
      project: {
        status: null,
        dates: {},
        units: [],
        buildings: [],
        prefilledDocuments: {},
        missingDocuments: [],
        submittedDocuments: [],
        gcrDocuments: []
      },
      projectDetailsLoading: false
    }
  },
  apollo: {
    getProjectDetails: {
      query: getProjectDetails,
      variables() {
        return {
          accreditationId: this.accreditationId,
          projectId: this.projectId
        }
      },
      update({ accreditation }) {
        this.project = accreditation.project
      },
      watchLoading(isLoading) {
        this.projectDetailsLoading = isLoading
      }
    }
  },
  computed: {
    ...mapGetters({
      accreditationId: Consts.GETTER_ACCREDITATION_CURRENT_ID
    }),
    ...mapState({
      accreditationActive: state => state.accreditation.isActive
    }),
    documentsCount() {
      return this.project.gcrDocuments.length + this.project.submittedDocuments.length
    }
  }
}

</script>
