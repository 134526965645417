<template>
  <div>
    <template v-if="isNew">
      <register-multiple-buildings-project-box
        v-if="isHorizontalCoOwnership"
        data-test-id="register-multiple-buildings-project"
        :project-id="project.id"
        :buildings="project.buildings"
      />
      <register-single-building-project-box
        v-else
        data-test-id="register-single-building-project"
        :project-id="project.id"
        :building="project.buildings[0]"
      />
    </template>
    <template v-else>
      <submitted-project-buildings
        data-test-id="submitted-project-buildings"
        :project-status="project.status"
        :registration-form-reception-date="project.dates.registrationFormReceptionDate"
        :registration-date="project.dates.registration"
        :project-id="project.id"
        :buildings="project.buildings"
      />
    </template>
    <project-fees
      data-test-id="fees"
      :project-id="project.id"
      :project-status="project.status"
      :balance="project.amountDue"
    />
    <document-links
      data-test-id="contracts"
      :title="$t('contracts')"
      :description="$t('contractsTextIntro')"
      :missing-documents="contractsMissingDocuments"
    >
      <router-link
        data-test-id="contracts-link"
        :to="links.contracts"
        class="btn btn-primary btn-sm btn-arrow"
      >
        {{ $t('accessContracts') }}
      </router-link>
    </document-links>
    <document-links
      data-test-id="end-of-work"
      :title="$t('endOfWorkNotice')"
      :documents="endOfWorkLinks"
      :missing-documents="endOfWorkMissingDocuments"
    />
    <document-links
      data-test-id="final-work"
      :title="$t('finalWorkPerformanceStatement')"
      :description="$t('finalWorkPerformanceStatementDesc')"
      :documents="links.finalWork"
    />
    <document-links
      data-test-id="pre-acceptance"
      :title="$t('preAcceptanceInspection')"
      :description="$t('preAcceptanceInspectionDesc')"
      :documents="links.preAcceptance"
      :missing-documents="preAcceptanceMissingDocuments"
    />
    <document-links
      data-test-id="other"
      :title="$t('otherProcedures')"
      :documents="otherLinks"
    />
  </div>
</template>

<script>
import DocumentLinks from '@/components/project/DocumentLinks'
import ProjectFees from '@/components/project/ProjectFees'
import RegisterMultipleBuildingsProjectBox from '@/components/project/RegisterMultipleBuildingsProjectBox'
import SubmittedProjectBuildings from '@/components/project/SubmittedProjectBuildings'
import RegisterSingleBuildingProjectBox from '@/components/project/RegisterSingleBuildingProjectBox'

export default {
  components: {
    RegisterMultipleBuildingsProjectBox,
    RegisterSingleBuildingProjectBox,
    SubmittedProjectBuildings,
    ProjectFees,
    DocumentLinks
  },
  props: {
    project: Object
  },
  data() {
    return {
      links: {
        contracts: this.currentPage({ ct: 'contracts' }),
        finalWork: [{
          link: 'https://www.garantiegcr.com/portail/formulaires/declaration-execution-finale-travaux',
          text: this.$t('downloadForm')
        }],
        preAcceptance: [{
          link: 'https://www.garantiegcr.com/portail/formulaires/inspection-prereception-maison',
          text: this.$t('downloadForm')
        }]
      }
    }
  },
  computed: {
    isNew() {
      return this.project.status === 'NEW'
    },
    isSoleOwnership() {
      return this.project.type === 'SOLE_OWNERSHIP'
    },
    isHorizontalCoOwnership() {
      return this.project.type === 'HORIZONTAL_CO_OWNERSHIP'
    },
    endOfWorkLinks() {
      return this.isSoleOwnership ? [
        {
          link: 'https://www.garantiegcr.com/portail/formulaires/avis-fin-travaux-maison',
          text: this.$t('downloadForm')
        }
      ] : [
        {
          link: 'https://www.garantiegcr.com/portail/formulaires/avis-fin-travaux-maison',
          text: this.$t('endOfWorkPrivate')
        },
        {
          link: 'https://www.garantiegcr.com/portail/formulaires/avis-fin-travaux-parties-communes',
          text: this.$t('endOfWorkCommon')
        }]
    },
    otherLinks() {
      return this.isSoleOwnership ? [
        {
          link: 'https://www.garantiegcr.com/portail/formulaires/annulation-enregistrement-maison',
          text: this.$t('cancelProjectRegistration')
        },
        {
          link: 'https://www.garantiegcr.com/portail/formulaires/liberation-garantie-24mois-suivants',
          text: this.$t('guaranteeRelease'),
          note: this.$t('24months')
        },
        {
          link: 'https://www.garantiegcr.com/fr/echo-gcr/frais-de-retard-denregistrement/',
          text: this.$t('lateFeesPolicy')
        },
        {
          link: 'https://www.garantiegcr.com/portail/enregistrement-locatif',
          text: this.$t('rentalProjects')
        }
      ] : [
        {
          link: 'https://www.garantiegcr.com/portail/formulaires/annulation-enregistrement-maison',
          text: this.$t('cancelProjectRegistration')
        },
        {
          link: 'https://www.garantiegcr.com/portail/formulaires/fiche-batiment-declaration-conformite',
          text: this.$t('buildingDeclarationOfConformity')
        },
        {
          link: 'https://www.garantiegcr.com/fr/echo-gcr/frais-de-retard-denregistrement/',
          text: this.$t('lateFeesPolicy')
        },
        {
          link: 'https://www.garantiegcr.com/portail/enregistrement-locatif',
          text: this.$t('rentalProjects')
        }
      ]
    },
    contractsMissingDocuments() {
      const missingDocuments = []
      if (this.anyDocumentMissing(u => u.guaranteeContractDate))
        missingDocuments.push(this.$t('missingGuaranteeContractDate'))
      if (this.anyDocumentMissing(u => u.salesContractDate))
        missingDocuments.push(this.$t('missingSalesContractDate'))
      return missingDocuments.length ? missingDocuments : null
    },
    endOfWorkMissingDocuments() {
      return this.anyDocumentMissing(u => u.completionNoticeDate) ?
        [this.$t('missingCompletionNoticeDate')] : null
    },
    preAcceptanceMissingDocuments() {
      return this.anyDocumentMissing(u => u.preAcceptanceInspectionDate) ?
        [this.$t('missingPreAcceptanceInspectionDate')] : null
    }
  },
  methods: {
    anyDocumentMissing(date) {
      return !this.isNew && !(this.project.units.length && this.project.units.every(date))
    },
    currentPage(queryParams) {
      return { query: { ...this.$route.query, ...queryParams } }
    }
  }
}
</script>
