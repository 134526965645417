<template>
  <div>
    <div class="page__header row justify-content-center">
      <div class="col col-xs-11 page__header__tab">
        <router-link
          data-test-id="tab-header"
          v-for="tab in tabs"
          :key="tab.name"
          class="page__header__tab__item"
          :class="{ 'page__header__tab__item--active': tab.name === activeTab }"
          :to="getTabLink(tab.name)"
          replace
        >
          {{ tab.title }}
          <badge
            v-if="tab.badge"
            data-test-id="badge"
            :class="getBadgeClass(tab.name)"
            :content="tab.badge" />
        </router-link>
      </div>
    </div>
    <div class="page__body row justify-content-center">
      <div class="col col-xs-11">
        <div class="row">
          <slot name="rightContent"></slot>
          <div class="col">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Badge from '@/components/atoms/Badge'

export default {
  components: { Badge },
  props: {
    activeTab: String
  },
  data() {
    return {
      tabs: null
    }
  },
  mounted() {
    this.tabs = this.$scopedSlots.default().map(c => c.componentInstance)
    this.updateActiveTab()
  },
  methods: {
    getTabLink(tabName) {
      return { query: { ...this.$route.query, ct: tabName}}
    },
    getBadgeClass(tabName) {
      return this.activeTab === tabName ? 'badge-gray' : 'badge-white'
    },
    updateActiveTab() {
      this.tabs.forEach(tab => tab.isActive = tab.name === this.activeTab)
    }
  },
  watch: {
    activeTab() {
      this.updateActiveTab()
    }
  }
}
</script>

<style lang="scss" scoped>
.page__header__tab__item {
  text-decoration: none;
}
</style>
