<template>
  <div class="box">
    <div class="box__header">
      <h2 data-test-id="heading" class="box__header-title box__header-title--green">{{ $t('registerTheProject') }}</h2>
    </div>
    <div class="box__body">
      <div data-test-id="register-project-rules" v-html="$t('registerProjectRules')"></div>
      <table v-if="hasBuilding" class="table building-list__table">
        <thead>
        <tr class="d-flex">
          <th class="col" data-test-id="address-header">{{ $t('buildingListAddress') }}</th>
          <th class="col" data-test-id="description-header">{{ $t('buildingListDescription') }}</th>
          <th class="col" data-test-id="lot-number-header">{{ $t('buildingListLotNumber') }}</th>
          <th v-if="activeAccreditation" class="col" data-test-id="edit-building-link-header" />
        </tr>
        </thead>
        <tbody>
        <tr data-test-id="building" class="d-flex">
          <td class="col" data-test-id="building-address">{{ shortAddress(building) }}</td>
          <td class="col" data-test-id="building-description">{{ buildingDescription(building) }}</td>
          <td class="col" data-test-id="building-lot-number">{{ building.lotNumber }}</td>
          <td v-if="activeAccreditation" class="col right-align">
            <router-link data-test-id="edit-building-link" :to="addEditBuildingLink">
              {{ $t('editBuilding') }}
            </router-link>
          </td>
        </tr>
        </tbody>
      </table>
      <router-link v-else-if="activeAccreditation" data-test-id="add-building-button" class="btn btn-primary btn-arrow btn-sm" :to="addEditBuildingLink">
        {{ $t('completeOnlineForm') }}
      </router-link>

    </div>
  </div>
</template>

<script>
import { shortAddress } from '@/_helpers/address'
import { buildingDescription } from '@/_helpers/buildings'
import { Urls } from '@/_helpers'

export default {
  props: {
    projectId: String,
    building: Object
  },
  computed: {
    hasBuilding() {
      return this.building
    },
    activeAccreditation() {
      return this.$store.state.accreditation.isActive
    },
    addEditBuildingLink() {
      return {
        name: Urls.REGISTER_PROJECT,
        query: { projectId: this.projectId, buildingId: this.building?.id }
      }
    }
  },
  methods: {
    shortAddress,
    buildingDescription
  }
}
</script>
