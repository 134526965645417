<template>
  <div class="box">
    <div class="box__header">
      <h2 class="box__header-title box__header-title--green">
        {{ $t('buyersInfo') }}
        <router-link
          v-if="buyers.length !== 0"
          class="project-info__edit btn btn-edit btn-edit-sm"
          :to="buyersLink"
          v-tooltip="$t('edit')"
        />
      </h2>
    </div>
    <div class="box__body">
      <p v-if="buyers.length === 0">{{ $t('buyersInfoDesc1') }}</p>
      <p v-else>{{ $t('buyersInfoDesc2') }}</p>

      <router-link
        v-if="buyers.length === 0"
        :to="buyersLink"
        class="btn btn-primary btn-sm btn-arrow">
        {{ $t('updateBuyersInfo') }}
      </router-link>
      <div v-else class="row">
        <div v-for="(buyer, index) in buyers" class="col" :key="index">
          <h3 class="buyer__subtitle">{{ $t('buyer') }} {{ index + 1 }}</h3>
          <p>
            {{ buyer.firstName }} {{ buyer.lastName }}<br />
            <a :href="'mailto:' + buyer.email">{{ buyer.email }}</a><br />
            <a :href="'tel:' + buyer.phoneNumber">{{ buyer.phoneNumber }}</a>
          </p>
          <p data-test-id="buyer-address">
            {{ buyer.streetNumber }} {{ buyer.street }}<br />
            {{ buyer.city }}
            <template v-if="buyer.province">({{ buyer.province }})</template>
            <br />
            <template v-if="buyer.country">{{ buyer.country }}</template>
            <template v-if="buyer.postalCode">{{ buyer.postalCode.toUpperCase() }}</template>
          </p>
        </div>
      </div>
    </div>
    <div class="box__footer box__footer--alert" v-if="buyers.length > 0">
      <p>{{ $t('buyerNote') }}</p>
    </div>
  </div>
</template>

<script>
import { Urls } from '@/_helpers'

export default {
  props: {
    projectId: String,
    buyers: Array
  },
  computed: {
    buyersLink() {
      return {
        name: Urls.BUYERS_INFO,
        query: { id: this.projectId }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.buyer {
  &__subtitle {
    margin-top: 15px;
  }
}
</style>
