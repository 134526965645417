<template>
  <div class="row justify-content-center">
    <div class="col-12 col-xs-11 page__header__content">
      <h1 class="page__header__title" data-test-id="title">{{ $t('projectDetailsHeader') }}</h1>
      <div class="project-details">
        <div>
          <strong data-test-id="name">{{ project.name }}</strong>
          <project-actions v-if="showProjectActions" data-test-id="project-actions" :project-id="project.id" />
          <a
            v-if="showExternalLink"
            data-test-id="external-link"
            class="external-link-green"
            :href="externalLink"
            target="_blank"
          />
        </div>
        <div data-test-id="type">{{ projectType }}</div>
        <div data-test-id="lot">{{ $t('projectDetailsLot', { projectLotNumber: this.projectLotNumber }) }}</div>
        <div data-test-id="city">{{ project.city }}</div>
      </div>
      <router-link data-test-id="back" class="form__header__back__link form__header__back__link--arrow" :to="projectsLink">
        {{ $t('goBack') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import ProjectActions from '@/components/project/ProjectActions'
import { mapGetters } from 'vuex'
import { Consts, Urls } from '@/_helpers'
import env from '@/_helpers/env'
import { decodeId } from '@/_helpers/graphql'

export default {
  components: {
    ProjectActions
  },
  data() {
    return {
      projectsLink: { name: Urls.PROJECTS },
      projectTypeTexts: {
        'SOLE_OWNERSHIP': this.$t('soleOwnershipType'),
        'VERTICAL_CO_OWNERSHIP': this.$t('verticalCoOwnershipType'),
        'HORIZONTAL_CO_OWNERSHIP': this.$t('horizontalCoOwnershipType')
      }
    }
  },
  props: {
    project: Object
  },
  computed: {
    ...mapGetters({
      isUserSAC: Consts.GETTER_USER_ISSAC
    }),
    projectType() {
      return this.projectTypeTexts[this.project.type]
    },
    isNew() {
      return this.project.status === 'NEW'
    },
    showProjectActions() {
      return this.$store.state.accreditation.isActive && this.isNew
    },
    showExternalLink() {
      return (['Local', 'QA'].includes(env.VUE_APP_ENVIRONMENT) || this.isUserSAC)
        && !this.isNew
        && !!this.project.id
    },
    externalLink() {
      return `${env.VUE_APP_NAV_URL}/#/projet/${decodeId(this.project.id)}`
    },
    projectLotNumber() {
      return this.project.type === 'HORIZONTAL_CO_OWNERSHIP' ? this.project.lotNumber : this.project.buildings[0]?.lotNumber
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/form/form';

.page__header__title {
  &::after {
    background-image: url(../../assets/home_gray.svg);
  }
}

.project-details {
  margin-bottom: 1rem;
}

.external-link-green {
  display: inline-block;
  margin-left: 5px;
  height: 24px;
  width: 24px;
  background-image: url(../../assets/external_link_green.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 60%;
  padding: 0;
  vertical-align: text-bottom;
}

</style>
