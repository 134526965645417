<template>
  <div v-if="hasMissingDocuments" class="box">
    <div class="box__header">
      <h2 data-test-id="header" class="box__header-title box__header--icon box__header--icon-info-warning">{{ $t('missingUnitsDocumentsTitle') }}</h2>
    </div>
    <div class="box__body">
      <ul>
        <li v-for="missingDocument in missingDocuments" :key="missingDocument" data-test-id="missing-document">{{ missingDocument }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    missingDocuments: Array
  },
  computed: {
    hasMissingDocuments() {
      return this.missingDocuments?.length
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';

.box__header--icon-info-warning:before {
  background-image: url(../../assets/error_circle_orange.svg);
  background-color: gray('white'); //prevent icon see-through
  border-radius: 100px;
  transform: rotate(180deg);
}
</style>