<template>
  <div class="box">
    <div class="box__header">
      <h2 data-test-id="title" class="box__header-title box__header-title--green">{{ title }}</h2>
    </div>
    <div class="box__body">
      <p data-test-id="description" v-if="description">{{ description }}</p>
      <slot>
        <ul class="project-box__links">
          <li
            data-test-id="documents"
            v-for="(document, index) in documents"
            :key="index"
          >
            <a :href="document.link" target="_blank">
              {{ document.text }}
            </a>
            <div v-if="document.note">{{ document.note }}</div>
          </li>
        </ul>
      </slot>
    </div>
    <div class="box__footer box__footer--alert" v-if="missingDocuments">
      <h4 data-test-id="missing-documents-title" class="box__footer-title">
        {{ $tc('missingDocumentsTitle', missingDocuments.length) }}
      </h4>
      <ul class="box__footer-list">
        <li
          data-test-id="missing-documents"
          v-for="(document, index) in missingDocuments"
          class="project-box__missing-item"
          :key="index"
        >
          {{ document }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
    documents: Array,
    missingDocuments: Array
  }
}
</script>

<style lang="scss" scoped>
.project-box {
  &__links {
    list-style: none;
    padding: 0;
  }

  &__missing-item {
    margin: 0;
  }
}
</style>
