<template>
  <div class="box">
    <div class="box__header box__header--icon box__header--icon-download">
      <h2 class="box__header-title">{{ $t('dropDocuments') }}</h2>
    </div>
    <div class="box__body">
      <select-gcr data-test-id="document-type" label="documentType" v-model="docType" :options="typeOptions" />
      <p>
        <small>{{ $t('pdfOnly') }}</small>
      </p>
      <div class="dropzone">
        <vue-dropzone
          data-test-id="dropzone"
          ref="dropzone"
          id="dropzone"
          :options="dropzoneOptions"
          v-on:vdropzone-success="fileUploaded"
          v-on:vdropzone-error="fileError"
          v-on:vdropzone-file-added="fileAdded"
          v-on:vdropzone-reset="fileReset"
        ></vue-dropzone>
        <div class="dropzone__btn">
          <button-gcr
            data-test-id="submit-document"
            btnClass="btn btn-primary btn-sm btn-arrow"
            label="submitDocument"
            @btnclick="submitDocument()"
            :loading="uploadingFile"
            :disabled="!fileIsPresent || !docType || isSAC"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Consts } from '@/_helpers'
import ButtonGcr from '@/components/atoms/Button'
import vueDropzone from 'vue2-dropzone'
import SelectGcr from '@/components/atoms/Select'
import { mapGetters } from 'vuex'
import env from '@/_helpers/env'

require('../../utilities/box')
require('../../utilities/button')

export default {
  components: { ButtonGcr, vueDropzone, SelectGcr },
  props: {
    docTypeOptions: Array,
    submitQuery: Object,
    submitQueryParams: Object
  },
  data() {
    return {
      docType: '',
      uploadingFile: false,
      fileIsPresent: false,
      dropzoneOptions: {
        url: `${env.VUE_APP_ROOT_API}/upload`,
        maxFilesize: 25, // Mo
        acceptedFiles: 'application/pdf',
        dictDefaultMessage: this.$t('dictDefaultMessage'),
        dictFileTooBig: this.$t('dictFileTooBig'),
        dictInvalidFileType: this.$t('dictInvalidFileType'),
        autoProcessQueue: false,
        createImageThumbnails: false,
        previewTemplate: this.dropZonePreviewTemplate(),
        timeout: Consts.UPLOAD_TIMEOUT
      },
      responseFile: null,
      uploadedFile: null,
      uploadTimeout: null,
      uploadInProgress: false
    }
  },
  computed: {
    ...mapGetters({
      isSAC: Consts.GETTER_USER_ISSAC
    }),
    typeOptions() {
      return this.docTypeOptions.map(x => ({ value: x.submitValue, text: x.text }))
    }
  },
  methods: {
    dropZonePreviewTemplate() {
      return `<div class="dz-preview">
          <div class="dz-preview__details">
            <div class="dz-preview__name" data-dz-name></div>
            <div class="dz-preview__size" data-dz-size></div>
            <button class="dz-preview__remove" type="button" data-dz-remove></button>
          </div>
          <div class="dz-preview__progress">
            <div class="dz-preview__progress-upload" data-dz-uploadprogress></div>
          </div>
          <div data-dz-errormessage></div>
        </div>`
    },
    fileAdded() {
      this.fileIsPresent = true
    },
    fileReset() {
      this.responseFile = null
      this.uploadedFile = null
      this.fileIsPresent = false
      this.uploadingFile = false
    },
    fileError(file, message) {
      if (!this.uploadInProgress) {
        return
      }

      clearTimeout(this.uploadTimeout)

      this.$refs.dropzone.removeFile(file)
      this.$store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_ERROR, text: message })
    },
    async submitDocument() {
      this.uploadingFile = true

      const token = await this.$store.dispatch(Consts.ACTION_USER_REFRESHTOKEN)
      this.$refs.dropzone.setOption('headers', { authorization: `Bearer ${token}` })

      if (this.responseFile) {
        return this.fileUploaded(this.uploadedFile, this.responseFile)
      }

      this.uploadInProgress = true
      this.uploadTimeout = setTimeout(() => this.fileTimeout(), Consts.UPLOAD_TIMEOUT)
      this.$refs.dropzone.processQueue()
    },
    fileTimeout() {
      this.uploadInProgress = false
      this.$refs.dropzone.removeAllFiles(true)
      this.$store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_ERROR, text: 'fileUploadTimeout' })
    },
    async fileUploaded(file, response) {
      clearTimeout(this.uploadTimeout)

      this.uploadInProgress = false

      try {
        await this.$apollo.mutate({
          mutation: this.submitQuery,
          variables: {
            input: {
              accreditationId: this.$store.getters[Consts.GETTER_ACCREDITATION_CURRENT_ID],
              documentType: this.docType,
              uploadedFileId: response.fileId,
              ...this.submitQueryParams,
            }
          }
        })
      } catch (error) {
        this.responseFile = response
        this.uploadedFile = file
        this.uploadingFile = false
        return this.$store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_ERROR, text: 'unexpectedError' })
      }

      this.$refs.dropzone.removeFile(file)
      if (this.$refs.dropzone.getAcceptedFiles().length) {
        return
      }

      this.fileIsPresent = false
      this.docType = null
      this.uploadingFile = false
      this.responseFile = null
      this.uploadedFile = null
      this.$store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_INFO, text: 'documentSuccess' })
      this.$emit('uploadDone')
    }
  }
}
</script>

<style lang="scss" scoped>
.box__header--icon-download:before {
  background-image: url(../../assets/download_green.svg);
}
</style>

<style lang="scss">
@import '../../utilities/config';
@import 'node_modules/vue2-dropzone/dist/vue2Dropzone.min';

.dropzone {
  margin-top: 16px;
  padding: 0;
  border: 0;
  min-height: auto;
  background: transparent;

  .dropzone {
    background-color: color('white');
    border: 2px dotted theme-color('primary');
    border-radius: 4px;
    padding: 16px;
    min-height: auto;

    .dz-message {
      margin: 2em 0;
    }

    .dz-default u {
      color: $link-color;
      &:hover {
        color: $link-hover-color;
      }
    }

    .dz-preview {
      background: theme-color('primary');
      border-radius: 4px;
      color: color('white');
      font-size: 14px;
      padding: 8px;
      position: relative;
      display: block;
      margin: 0;
      min-height: auto;
      strong {
        color: inherit;
      }
      & + & {
        margin-top: 16px;
      }
      &__details {
        align-items: center;
        display: flex;
        flex: 0 0 auto;
        width: 100%;
      }
      &__name {
        display: block;
        flex: 1 1 auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &__size {
        display: block;
        flex: 0 0 auto;
        margin-left: 8px;
      }
      &__remove {
        //@include btn-default-properties;
        background: transparent;
        border: none;
        border-radius: 0;
        color: inherit;
        cursor: pointer;
        padding: 0;
        text-decoration: none;
        user-select: none;
        white-space: normal;

        display: block;
        flex: 0 0 auto;
        background: url(../../assets/close_white.svg) no-repeat 50% 40%;
        background-size: auto 12px;
        height: 1.5em;
        width: 1.5em;
        margin: 0 -3px 0 5px;
      }
      &__progress {
        border: 1px solid theme-color('faded-green');
        border-radius: 9px;
        height: 8px;
        margin: 4px 0;
        &-upload {
          background: theme-color('faded-green');
          border-radius: 9px;
          height: 6px;
          width: 0;
        }
      }
    }
  }
  &__btn {
    margin-top: 15px;
    text-align: center;
    > :last-child {
      margin-bottom: 0;
    }
  }
}
</style>
