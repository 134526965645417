export default [
  { value: 'guaranteeContract', submitValue: 'GUARANTEE_CONTRACT', text: 'guaranteeContract' },
  { value: 'preliminaryContract', submitValue: 'PRELIMINARY_CONTRACT', text: 'preliminaryContract' },
  { value: 'contractOfEnterprise', submitValue: 'CONTRACT_OF_ENTERPRISE', text: 'contractOfEnterprise' },
  { value: 'partialPaymentCertificate', submitValue: 'PARTIAL_PAYMENT_CERTIFICATE', text: 'partialPaymentCertificate' },
  { value: 'contractModifications', submitValue: 'CONTRACT_MODIFICATIONS', text: 'contractModifications' },
  { value: 'contractOtherConditions', submitValue: 'CONTRACT_OTHER_CONDITIONS', text: 'contractOtherConditions' },
  { value: 'endOfWorkNotice', submitValue: 'END_OF_WORK_NOTICE', text: 'endOfWorkNotice' },
  { value: 'finalWorkPerformanceStatement', submitValue: 'FINAL_WORK_PERFORMANCE_STATEMENT', text: 'finalWorkPerformanceStatement' },
  { value: 'preAcceptanceInspectionForm', submitValue: 'PRE_ACCEPTANCE_INSPECTION_FORM', text: 'preAcceptanceInspectionForm' },
  { value: 'guaranteeRefundForm', submitValue: 'GUARANTEE_REFUND_FORM', text: 'guaranteeRefundForm' },
  { value: 'other', submitValue: 'OTHER', text: 'other' }
]
