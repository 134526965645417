<template>
  <div v-if="isShown" class="box">
    <div class="box__header">
      <h2 data-test-id="fees-title" class="box__header-title box__header-title--green">{{ $t('payProject') }}</h2>
    </div>
    <div class="box__body">
      <template v-if="isNew">
        <p data-test-id="fees-description">{{ $t('payProjectDesc') }}</p>
        <ul data-test-id="fees-payment-methods">
          <li>{{ $t('bankingMethod') }}</li>
          <li>{{ $t('byCheque') }}</li>
        </ul>
        <a data-test-id="fees-schedule" :href="paymentScheduleLink" target="_blank">{{ $t('feeSchedule') }}</a>
      </template>
      <template v-else-if="isSubmitted">
        <p data-test-id="fees-status"><em>{{ $t('ongoingTreatment') }}</em></p>
        <p data-test-id="fees-description">{{ $t('ongoingTreatmentDesc') }}</p>
        <a data-test-id="fees-schedule" :href="paymentScheduleLink" target="_blank">{{ $t('feeSchedule') }}</a>
      </template>
      <template v-else>
        <p data-test-id="fees-balance"><strong>{{ projectBalance }}</strong></p>
        <router-link data-test-id="invoices-link" :to="invoicesLink" class="btn btn-primary btn-sm btn-arrow">
          {{ $t('invoiceAndDetails') }}
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import { Cash } from '@/_helpers/cash.js'
import { Urls } from '@/_helpers'

export default {
  props: {
    projectId: String,
    projectStatus: String,
    balance: Number
  },
  data() {
    return {
      paymentScheduleLink: 'https://www.garantiegcr.com/fr/entrepreneurs/enregistrement/tarifs-denregistrement/'
    }
  },
  computed: {
    invoicesLink() {
      return {
        name: Urls.PAY_PROJECT,
        query: { id: this.projectId }
      }
    },
    isShown() {
      return this.isNew || this.isSubmitted || this.isRegistered
    },
    isNew() {
      return this.projectStatus === 'NEW'
    },
    isSubmitted() {
      return this.projectStatus === 'SUBMITTED'
    },
    isRegistered() {
      return this.projectStatus === 'REGISTERED' && this.balance
    },
    projectBalance() {
      return this.$t('projectBalance', { balance: Cash.format(this.balance) })
    }
  }
}
</script>
