<template>
  <div class="box">
    <div class="box__header">
      <h2 data-test-id="contracts-title" class="box__header-title box__header-title--green">{{ $t('contracts') }}</h2>
    </div>
    <div class="box__body">
      <p data-test-id="contracts-description">{{ $t('contractsText3') }}</p>
    </div>
    <contract-item
      v-for="(contract, index) in contractItems"
      data-test-id="contract-item"
      :key="index"
      :title="contract.title"
      :create-label="contract.label"
      :create-link="contract.link"
      :contracts="contract.items"
    />
    <div class="box__body">
      <links data-test-id="missing-contracts" :documents="missingContracts" />
    </div>
  </div>
</template>

<script>
import ContractItem from '@/components/project/ContractItem'
import Links from '@/components/project/Links'

export default {
  components: {
    Links,
    ContractItem
  },
  props: {
    contracts: Array
  },
  computed: {
    contractItems() {
      return this.contracts
        .filter(c => c.items?.length)
    },
    missingContracts() {
      return this.contracts
        .filter(c => !c.items?.length)
        .map(c => ({ label: c.title, link: c.link }))
    }
  }
}
</script>
