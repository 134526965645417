<template>
  <div class="box">
    <div class="box__header">
      <h2 data-test-id="heading" class="box__header-title box__header-title--green">{{ $tc('buildingList', buildings.length) }}</h2>
    </div>
    <div class="box__body">
      <table data-test-id="building-list" class="table building-list__table">
        <thead>
        <tr class="d-flex">
          <th class="col" data-test-id="address-header">{{ $t('buildingListAddress') }}</th>
          <th class="col" data-test-id="description-header">{{ $t('buildingListDescription') }}</th>
          <th class="col center-align" data-test-id="lot-number-header">{{ $t('buildingListLotNumber') }}</th>
          <!--          <th class="col center-align" data-test-id="registration-number-header">{{ $t('buildingListRegistrationNumber') }}</th>-->
          <th class="col" />
        </tr>
        </thead>
        <tbody>
        <tr data-test-id="building" class="d-flex" v-for="building in buildings" :key="building.id">
          <td class="col" data-test-id="building-address">{{ shortAddress(building) }}</td>
          <td class="col" data-test-id="building-description">{{ description(building) }}</td>
          <td class="col center-align" data-test-id="building-lot-number">{{ building.lotNumber }}</td>
          <!--          <td class="col center-align" data-test-id="building-registration-number">&#45;&#45;</td>-->
          <td class="col right-align">
            <router-link
              data-test-id="building-construction-schedule-link"
              class="construction-schedule"
              :to="editConstructionScheduleLink(building.id)"
            >
              {{ $t('constructionScheduleEdit') }}
            </router-link>
          </td>
        </tr>
        </tbody>
      </table>
      <project-registration data-test-id="registration-info" v-bind="$props" />
    </div>
  </div>
</template>

<script>
import ProjectRegistration from '@/components/project/ProjectRegistration'
import { buildingDescription } from '@/_helpers/buildings'
import { shortAddress } from '@/_helpers/address'
import { Urls } from '@/_helpers'

export default {
  components: {
    ProjectRegistration,
  },
  props: {
    buildings: Array,
    projectId: String,
    projectStatus: String,
    registrationFormReceptionDate: String,
    registrationDate: String
  },
  methods: {
    shortAddress,
    description(building) {
      return buildingDescription(building) || '--'
    },
    editConstructionScheduleLink(buildingId) {
      return {
        name: Urls.EDIT_BUILDING_CONSTRUCTION_SCHEDULE,
        query: {
          projectId: this.projectId,
          buildingId
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../utilities/config';
@import '../../utilities/table/table';

.construction-schedule::before {
  position: absolute;
  content: '';
  transform: translate(-150%);
  height: 18px;
  width: 18px;
  background-image: url(../../assets/calendar_dark_green.svg);
  background-size: 1.3em 1.3em;
}

$header-background: rgb(235, 235, 237);
$border-color: rgb(201, 201, 201);

.building-list {
  &__table {
    border-collapse: separate;
    border-spacing: 0;

    .center-align {
      text-align: center;
    }

    .right-align {
      text-align: right;
    }

    tr {
      border: 2px solid $border-color;
    }

    thead tr {
      background-color: $header-background;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      th {
        border: 0;
        color: gray('gray-700');
      }
    }

    tbody tr {
      border-top-width: 0;

      td {
        border: 0;
      }

      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}

</style>
