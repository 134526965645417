<template>
  <div>
    <template v-if="isSubmitted">
      <p data-test-id="registration-submit-date">
        <em>{{ $t('submitDate', { submitDate: formatDate(registrationFormReceptionDate) }) }}</em>
      </p>
    </template>
    <template v-else-if="isRegistered">
      <p data-test-id="registration-date">{{ $t('registrationDate', { registrationDate: formatDate(registrationDate) }) }}</p>
      <p>
        <a
          data-test-id="cancel-registration"
          :href="cancelRegistrationLink"
          target="_blank"
        >
          {{ $t('cancelProjectRegistration') }}
        </a>
      </p>
    </template>
  </div>
</template>

<script>
import { Dates } from '@/_helpers/dates.js'

export default {
  props: {
    buildings: Array,
    projectId: String,
    projectStatus: String,
    accreditationActive: Boolean,
    registrationFormReceptionDate: String,
    registrationDate: String
  },
  data() {
    return {
      cancelRegistrationLink: 'https://www.garantiegcr.com/portail/formulaires/annulation-enregistrement-maison'
    }
  },
  computed: {
    isSubmitted() {
      return this.projectStatus === 'SUBMITTED'
    },
    isRegistered() {
      return this.projectStatus === 'REGISTERED'
    }
  },
  methods: {
    formatDate: Dates.formatDate
  }
}
</script>

<style scoped>

</style>
