<template>
  <div class="col-12 col-md-4 order-md-1">
    <missing-documents data-test-id="missing-documents" :missing-documents="missingDocuments" />
    <upload-doc-box
      v-if="canTransmitFile"
      data-test-id="upload-documents-box"
      :doc-type-options="projectDocumentTypes"
      :submit-query="submitProjectDocument"
      :submit-query-params="{ projectId }"
    />
  </div>
</template>

<script>
import MissingDocuments from '@/components/project/MissingDocuments'
import UploadDocBox from '@/components/organism/UploadDocBox'
import submitProjectDocument from '@/graphql/accreditations/projects/submitProjectDocument.graphql'
import projectDocumentTypes from '@/_models/project_document_types'

export default {
  props: {
    projectId: String,
    projectStatus: String,
    accreditationActive: Boolean,
    missingDocuments: Array
  },
  components: {
    MissingDocuments,
    UploadDocBox
  },
  data() {
    return {
      submitProjectDocument,
      projectDocumentTypes
    }
  },
  computed: {
    canTransmitFile() {
      return this.accreditationActive && !['NEW', 'CLOSED'].includes(this.projectStatus)
    }
  }
}
</script>
