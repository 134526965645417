<template>
  <div v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    name: String,
    badge: null
  },
  data() {
    return {
      isActive: false
    }
  }
}
</script>
