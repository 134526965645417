import { Consts } from '@/_helpers/consts'
import store from '@/_store'

export default {
  showError(text) {
    this.showAlert(Consts.ALERT_TYPE_ERROR, text)
  },
  showInfo(text) {
    this.showAlert(Consts.ALERT_TYPE_INFO, text)
  },
  showAlert(type, text) {
    store.dispatch(Consts.ACTION_ALERT_SHOW, { type, text })
  }
}
