<template>
  <div>
    <buyers v-if="isSoleOwnership" data-test-id="buyers" :project-id="projectId" :buyers="buyers" />
    <contracts-box data-test-id="contracts-box" :contracts="contracts" />
  </div>
</template>

<script>
import ContractsBox from '@/components/project/ContractsBox'
import Buyers from '@/components/project/Buyers'
import env from '@/_helpers/env'

export default {
  components: {
    Buyers,
    ContractsBox
  },
  props: {
    projectId: String,
    projectType: String,
    submittedDocuments: Array,
    buyers: Array,
    prefilledDocuments: Object
  },
  computed: {
    contracts() {
      return this.isSoleOwnership ? this.soleOwnershipContracts : this.coOwnershipContracts
    },
    isSoleOwnership() {
      return this.projectType === 'SOLE_OWNERSHIP'
    },
    soleOwnershipContracts() {
      return [
        {
          title: this.$t('guaranteeContract'),
          label: this.$t('createNewGuaranteeContract'),
          link: `${env.VUE_APP_ROOT_API}${this.prefilledDocuments.guaranteeContract}`,
          items: this.contractsFor('guaranteeContract')
        },
        {
          title: this.$t('preliminaryContract'),
          label: this.$t('createNewPreliminaryContract'),
          link: `${env.VUE_APP_ROOT_API}${this.prefilledDocuments.preliminaryContract}`,
          items: this.contractsFor('preliminaryContract')
        },
        {
          title: this.$t('contractOfEnterprise'),
          label: this.$t('createNewContractOfEnterprise'),
          link: `${env.VUE_APP_ROOT_API}${this.prefilledDocuments.enterpriseContract}`,
          items: this.contractsFor('contractOfEnterprise')
        },
        ...this.commonContracts
      ]
    },
    coOwnershipContracts() {
      return [
        {
          title: this.$t('guaranteeContract'),
          label: this.$t('createNewGuaranteeContract'),
          link: 'https://www.garantiegcr.com/portail/contrats/contrat-garantie-condo',
          items: this.contractsFor('guaranteeContract')
        },
        {
          title: this.$t('preliminaryContract'),
          label: this.$t('createNewPreliminaryContract'),
          link: 'https://www.garantiegcr.com/portail/contrats/contrat-preliminaire-condos',
          items: this.contractsFor('preliminaryContract')
        },
        {
          title: this.$t('contractParking'),
          link: 'https://www.garantiegcr.com/portail/contrats/annexes-contrat-preliminaire-stationnement'
        },
        {
          title: this.$t('contractCoOwnershipCoordinates'),
          link: 'https://www.garantiegcr.com/portail/contrats/annexes-contrat-preliminaire-coordonnees-generales-copropriete'
        },
        ...this.commonContracts
      ]
    },
    commonContracts() {
      return [
        {
          title: this.$t('contractModifications'),
          label: this.$t('createNewContractModification'),
          link: 'https://www.garantiegcr.com/portail/contrats/annexes-contrat-preliminaire-inclusion-exclusion-modifications',
          items: this.contractsFor('contractModifications')
        },
        {
          title: this.$t('contractOtherConditions'),
          label: this.$t('createNewContractOtherModification'),
          link: 'https://www.garantiegcr.com/portail/contrats/annexes-contrat-preliminaire-autres-conditions',
          items: this.contractsFor('contractOtherConditions')
        },
        {
          title: this.$t('partialPaymentCertificate'),
          label: this.$t('createNewPartialPaymentCertificate'),
          link: 'https://www.garantiegcr.com/portail/formulaires/attestation-accompte',
          items: this.contractsFor('partialPaymentCertificate')
        }
      ]
    }
  },
  methods: {
    contractsFor(type) {
      return this.submittedDocuments.filter(c => c.documentType === type)
    }
  }
}
</script>
