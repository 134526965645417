<template>
  <span>
    <router-link
      data-test-id="edit-project"
      class="btn btn-edit btn-edit-sm project-actions"
      :to="editProjectLink"
      v-tooltip="$t('edit')"
    />
    <confirmation-popover
      data-test-id="delete-project"
      class="project-actions"
      btn-class="btn btn-delete btn-delete-sm"
      btn-tooltip-text="deleteProjectConfirmationAcceptButton"
      confirm-title="deleteProjectConfirmationTitle"
      confirm-text="deleteProjectConfirmationText"
      accept-button-text="deleteProjectConfirmationAcceptButton"
      cancel-button-text="deleteProjectConfirmationCancelButton"
      :accept-button-disabled="isReadonly"
      @confirm="deleteProject"
    />
  </span>
</template>

<script>
import ConfirmationPopover from '@/components/molecules/ConfirmationPopover'
import { Consts, Urls } from '@/_helpers'
import { mapGetters } from 'vuex'
import deleteProject from '@/graphql/accreditations/projects/deleteProject.graphql'

export default {
  components: {
    ConfirmationPopover
  },
  props: {
    projectId: String
  },
  data() {
    return {
      links: {
        projects: { name: Urls.PROJECTS }
      }
    }
  },
  computed: {
    ...mapGetters({
      accreditationId: Consts.GETTER_ACCREDITATION_CURRENT_ID,
      isReadonly: Consts.GETTER_USER_ISSAC
    }),
    editProjectLink() {
      return {
        name: Urls.EDIT_NEW_PROJECT,
        query: { id: this.projectId }
      }
    }
  },
  methods: {
    deleteProject() {
      return this.$apollo
        .mutate({
          mutation: deleteProject,
          variables: {
            input: {
              accreditationId: this.accreditationId,
              projectId: this.projectId
            }
          }
        })
        .then(() => this.$router.replace(this.links.projects))
    }
  }
}
</script>

<style lang="scss" scoped>
.project-actions {
  margin-left: 10px;
}
</style>
