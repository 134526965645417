<template>
  <ul class="project-box__links">
    <li
      data-test-id="documents"
      v-for="(document, index) in documents"
      :key="index"
    >
      <a :href="document.link" target="_blank">
        {{ document.label }}
      </a>
      <span v-if="document.note"> {{ document.note }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    documents: Array
  }
}
</script>

<style lang="scss" scoped>
.project-box {
  &__links {
    list-style: none;
    padding: 0;
  }
}
</style>
