<template>
  <div>
    <router-link
      data-test-id="copy-button"
      class=" btn btn-copy building-button"
      :to="copyBuildingLink"
      v-tooltip="$t('copyBuilding')"
    />
    <router-link
      data-test-id="edit-button"
      class="btn btn-edit btn-edit-sm building-button"
      :to="editBuildingLink"
      v-tooltip="$t('editBuilding')"
    />
    <confirmation-popover
      class="building-button"
      data-test-id="delete-button"
      btn-class="btn btn-delete btn-delete-sm"
      btn-tooltip-text="deleteBuildingConfirmationAcceptButton"
      confirm-title="deleteBuildingConfirmationTitle"
      accept-button-text="deleteBuildingConfirmationAcceptButton"
      cancel-button-text="deleteBuildingConfirmationCancelButton"
      :accept-button-disabled="isReadonly"
      @confirm="deleteBuilding"
    />
  </div>
</template>

<script>
import { Consts, Urls } from '@/_helpers'
import { mapGetters } from 'vuex'
import ConfirmationPopover from '@/components/molecules/ConfirmationPopover'
import deleteBuilding from '@/graphql/accreditations/projects/deleteBuilding.graphql'
import alerts from '@/_helpers/alerts'

export default {
  components: { ConfirmationPopover },
  props: {
    projectId: String,
    buildingId: String
  },
  computed: {
    ...mapGetters({
      accreditationId: Consts.GETTER_ACCREDITATION_CURRENT_ID,
      isReadonly: Consts.GETTER_USER_ISSAC
    }),
    editBuildingLink() {
      return {
        name: Urls.EDIT_BUILDING,
        query: { projectId: this.projectId, buildingId: this.buildingId }
      }
    },
    copyBuildingLink() {
      return {
        name: Urls.EDIT_BUILDING,
        query: { projectId: this.projectId, buildingId: this.buildingId, copy: true }
      }
    }
  },
  methods: {
    deleteBuilding() {
      return this.$apollo
        .mutate({
          mutation: deleteBuilding,
          variables: {
            input: {
              accreditationId: this.accreditationId,
              projectId: this.projectId,
              buildingId: this.buildingId
            }
          },
          update: (cache, { data: { deleteBuilding } }) => {
            return cache.evict({ id: cache.identify({ id: deleteBuilding, __typename: 'Building' }) })
          }
        })
        .then(() => alerts.showInfo('deleteBuildingDraftSuccess'))
    }
  }
}


</script>

<style lang="scss" scoped>
.building-button {
  margin-left: 10px;
}
</style>
