<template>
  <div>
    <documents-box
      data-test-id="gcr-documents-box"
      title="documentsHistoryGCR"
      :documentsTypes="documentTypes"
      :documents="mappedGcrDocuments"
      :paging="docsPerPage"
    />
    <documents-box
      data-test-id="submitted-documents-box"
      title="documentsHistory"
      :documentsTypes="documentTypes"
      :documents="mappedSubmittedDocuments"
      :paging="docsPerPage"
    />
  </div>
</template>

<script>
import DocumentsBox from '@/components/organism/DocumentsBox'
import env from '@/_helpers/env'
import projectDocumentTypes from '@/_models/project_document_types'

export default {
  components: { DocumentsBox },
  props: {
    gcrDocuments: Array,
    submittedDocuments: Array
  },
  data() {
    return {
      documentTypes: projectDocumentTypes,
      docsPerPage: 5
    }
  },
  computed: {
    mappedGcrDocuments() {
      return this.gcrDocuments.map(this.mapDocument)
    },
    mappedSubmittedDocuments() {
      return this.submittedDocuments.map(this.mapDocument)
    }
  },
  methods: {
    mapDocument(doc) {
      return {
        id: doc.id,
        link: `${env.VUE_APP_ROOT_API}${doc.downloadUrl}`,
        name: doc.fileName,
        date: doc.uploadedAt,
        type: doc.documentType
      }
    }
  }
}
</script>
