<template>
  <div class="box">
    <div class="box__header">
      <h2 data-test-id="heading" class="box__header-title box__header-title--green">{{ $t('registerTheProject') }}</h2>
    </div>
    <div class="box__body">
      <div data-test-id="register-project-rules" v-html="$t('registerProjectRules')"></div>
      <table v-if="hasBuildings" data-test-id="building-list" class="table building-list__table">
        <thead>
        <tr class="d-flex">
          <th class="col" data-test-id="address-header">{{ $t('buildingListAddress') }}</th>
          <th class="col" data-test-id="description-header">{{ $t('buildingListDescription') }}</th>
          <th class="col" data-test-id="lot-number-header">{{ $t('buildingListLotNumber') }}</th>
          <th class="col" data-test-id="status-header" />
          <th v-if=activeAccreditation class="col" data-test-id="actions-header" />
        </tr>
        </thead>
        <tbody>
        <tr data-test-id="building" class="d-flex" v-for="building in buildings" :key="building.id">
          <td class="col" data-test-id="building-address">{{ shortAddress(building) }}</td>
          <td class="col" data-test-id="building-description">{{ description(building) }}</td>
          <td class="col" data-test-id="building-lot-number">{{ building.lotNumber }}</td>
          <td class="col">
            <span
              data-test-id="building-status"
              :class="buildingStatusClass(building.isDraft)"
              v-tooltip="buildingStatusTooltip(building.isDraft)"
            />
          </td>
          <td v-if="activeAccreditation" class="col right-align">
            <building-actions data-test-id="actions" :project-id="projectId" :building-id="building.id" />
          </td>
        </tr>
        </tbody>
      </table>
      <template v-if="activeAccreditation">
        <router-link data-test-id="add-building-button" class="btn btn-primary btn-arrow btn-sm" :to="addBuildingLink">
          {{ $t('addBuilding') }}
        </router-link>
        <confirmation-popover
          v-if="hasBuildings"
          data-test-id="submit-project-button"
          label="submitProject"
          btn-class="btn btn-primary btn-sm submit-button"
          accept-button-text="submitProjectConfirmationAcceptButton"
          cancel-button-text="submitProjectConfirmationCancelButton"
          :confirm-title="submitProjectConfirmation.title"
          :confirm-text="submitProjectConfirmation.text"
          :accept-button-disabled="submitProjectConfirmation.acceptDisabled"
          @confirm="confirmSubmission"
          :loading="submitting"
        />
      </template>
    </div>
  </div>
</template>


<script>
import ProjectRegistration from '@/components/project/ProjectRegistration'
import { buildingDescription } from '@/_helpers/buildings'
import { shortAddress } from '@/_helpers/address'
import { Consts, Urls } from '@/_helpers'
import BuildingActions from '@/components/project/BuildingActions'
import ConfirmationPopover from '@/components/molecules/ConfirmationPopover'
import submitProjectRegistration from '@/graphql/accreditations/projects/submitProjectRegistration.graphql'
import { mapGetters } from 'vuex'
import alerts from '@/_helpers/alerts'

export default {
  components: {
    ProjectRegistration,
    BuildingActions,
    ConfirmationPopover
  },
  data() {
    return {
      submitting: false
    }
  },
  props: {
    projectId: String,
    buildings: Array
  },
  computed: {
    ...mapGetters({
      accreditationId: Consts.GETTER_ACCREDITATION_CURRENT_ID,
      isReadonly: Consts.GETTER_USER_ISSAC
    }),
    submitProjectConfirmation() {
      if (this.buildings.every(b => !b.isDraft))
        return {
          title: 'submitMultiBuildingsProjectConfirmationReadyTitle',
          text: 'submitMultiBuildingsProjectConfirmationReadyText',
          acceptDisabled: this.isReadonly
        }
      return {
        title: 'submitMultiBuildingsProjectConfirmationNotReadyTitle',
        text: 'submitMultiBuildingsProjectConfirmationNotReadyText',
        acceptDisabled: true
      }
    },
    hasBuildings() {
      return this.buildings.length > 0
    },
    activeAccreditation() {
      return this.$store.state.accreditation.isActive
    },
    addBuildingLink() {
      return {
        name: Urls.EDIT_BUILDING,
        query: { projectId: this.projectId }
      }
    }
  },
  methods: {
    shortAddress,
    description(building) {
      return buildingDescription(building) || '--'
    },
    buildingStatusClass(isDraft) {
      return [isDraft ? 'building-draft' : 'building-ready-to-submit']
    },
    buildingStatusTooltip(isDraft) {
      return !isDraft ? this.$t('buildingReadyToSubmit') : this.$t('buildingDraft')
    },
    confirmSubmission() {
      this.submitting = true
      return this.submitRegistration().then(newProjectId => {
        alerts.showInfo('submitProjectRegistrationSuccess')
        this.$router.push({
          name: Urls.PROJECT_DETAILS,
          query: { id: newProjectId }
        })
      }).finally(() => this.submitting = false)
    },
    submitRegistration() {
      return this.$apollo
        .mutate({
          mutation: submitProjectRegistration,
          variables: {
            input: {
              accreditationId: this.accreditationId,
              projectId: this.projectId
            }
          }
        })
        .then(({ data: { submitProjectRegistration: newProjectId } }) => newProjectId)
    }
  }
}
</script>

<style lang="scss">
@import '../../utilities/config';
@import '../../utilities/table/table';

$header-background: rgb(235, 235, 237);
$border-color: rgb(201, 201, 201);

.building-ready-to-submit {
  display: inline-block;
  margin-left: 5px;
  height: 24px;
  width: 24px;
  background-image: url(../../assets/check_green.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80%;
  padding: 0;
  vertical-align: text-bottom;
}

.building-draft {
  display: inline-block;
  margin-left: 5px;
  height: 24px;
  width: 24px;
  background-image: url(../../assets/progress_bar_gray.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 0;
  vertical-align: text-bottom;
}

.submit-button {
  margin-left: 10px;
}

.building-list {
  &__table {
    border-collapse: separate;
    border-spacing: 0;

    .center-align {
      text-align: center;
    }

    .right-align {
      text-align: right;
    }

    tr {
      border: 2px solid $border-color;
    }

    thead tr {
      background-color: $header-background;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      th {
        border: 0;
        color: gray('gray-700');
      }
    }

    tbody tr {
      border-top-width: 0;

      td {
        border: 0;
      }

      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}

</style>
